body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-weight: 300 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.xs,
.xs-only {
    display: none;
}

.sm-only {
    display: none;
}

.not-sm {
    display: block;
}

.not-xs {
    display: block;
}

.calendar-grid {
    height: 750px;
}

.calendar-wrapper {
    height: 675px;
}

.mfaInput input {
    color: transparent;
    text-shadow: 0 0 0 black;
}

.MuiExpansionPanelDetails-root > div {
    width: 100%;
}

@media (max-width:959.95px) {
    .sm-only {
        display: block;
    }
    .not-sm {
        display: none!important;
    }
    .calendar-wrapper {
        height: 590px;
    }
    .MuiTablePagination-spacer {
        display: none;
    }
    .MuiTablePagination-toolbar.MuiToolbar-gutters {
        padding: 0;
        margin: 0;
    }
}

@media (max-width:599.95px) {
    .xs,
    .xs-only {
        display: block;
    }
    .not-xs {
        display: none!important;
    }
    .xs-max-width {
        max-width: calc(100vw - 40px);
    }
    body {
        -webkit-user-select: none;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;    
        -webkit-tap-highlight-color: transparent;
    }
}

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
    width:0.25em
}

::-webkit-scrollbar-track {
    background: #fafafa;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
}

::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
}

.SmallScroll::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
}

/* Fix floating labels on autocomplete */
input:-webkit-autofill {
    transition-delay: 9999999s;
    -webkit-transition-delay: 9999999s;
}
.MuiExpansionPanelSummary-root.white {
    color: #000;
    background-color: #fff;
}
.MuiExpansionPanelSummary-root.white > .MuiExpansionPanelSummary-expandIcon {
    color: #000;
}

.MuiExpansionPanelSummary-root.green {
    color: #fff;
    background-color:#449D44;
}
.MuiExpansionPanelSummary-root.green > .MuiExpansionPanelSummary-expandIcon {
    color: #fff;
}

.MuiExpansionPanelSummary-root.red {
    color: #fff;
    background-color: #d9534f;
}
.MuiExpansionPanelSummary-root.red > .MuiExpansionPanelSummary-expandIcon {
    color: #fff;
}

.MuiExpansionPanelSummary-root.orange {
    color: #fff;
    background-color: #f0ad4e;
}
.MuiExpansionPanelSummary-root.orange > .MuiExpansionPanelSummary-expandIcon {
    color: #fff;
}

.MuiExpansionPanelSummary-root.disabled {
    color: #fff;
    background-color: #449D44;
}
    
.MuiExpansionPanelSummary-root.disabled > .MuiExpansionPanelSummary-expandIcon {
    color: #fff;
}

.MuiExpansionPanel-root.Mui-disabled {
    color: #000;
    background-color:#f1f1f1;
}
.MuiExpansionPanel-root.Mui-disabled {
    color: #000;
}

.MuiInput-underline:after {
    transition: none;
  }
  
/* Fix floating label on outlined textfields / multiline textfields */
.MuiInputLabel-outlined {
    z-index: 0!important
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.p-0 {
    padding: 0!important;
}

.p-1 {
    padding: 8px!important;
}

.p-2 {
    padding: 16px!important;
}

.p-3 {
    padding: 24px!important;
}

.pb-0 {
    padding-bottom: 0!important;
}

.pb-1 {
    padding-bottom: 8px!important;
}

.pb-2 {
    padding-bottom: 16px!important;
}

.pb-3 {
    padding-bottom: 24px!important;
}

.pt-0 {
    padding-top: 0!important;
}

.pt-1 {
    padding-top: 8px!important;
}

.pt-2 {
    padding-top: 16px!important;
}

.pt-3 {
    padding-top: 24px!important;
}

.pl-0 {
    padding-left: 0!important;
}

.pl-1 {
    padding-left: 8px!important;
}

.pl-2 {
    padding-left: 16px!important;
}

.pl-3 {
    padding-left: 24px!important;
}

.pr-0 {
    padding-right: 0!important;
}

.pr-05 {
    padding-right: 4px!important;
}

.pr-1 {
    padding-right: 8px!important;
}

.pr-2 {
    padding-right: 16px!important;
}

.pr-3 {
    padding-right: 24px!important;
}

.m-0 {
    margin: 0!important;
}

.m-1 {
    margin: 8px!important;
}

.m-2 {
    margin: 16px!important;
}

.m-3 {
    margin: 24px!important;
}

.ml-1 {
    margin-left: 8px!important;
}

.ml-2 {
    margin-left: 16px!important;
}

.ml-3 {
    margin-left: 24px!important;
}

.mb-0 {
    margin-bottom: 0!important;
}

.mb-1 {
    margin-bottom: 8px!important;
}

.mb-2 {
    margin-bottom: 16px!important;
}

.mb-3 {
    margin-bottom: 24px!important;
}

.mb-4 {
    margin-bottom: 32px!important;
}

.mt-1 {
    margin-top: 8px!important;
}

.mt-2 {
    margin-top: 16px!important;
}

.mt-3 {
    margin-top: 24px!important;
}

.mt-4 {
    margin-top: 32px!important;
}

.mr-1 {
    margin-right: 8px!important;
}

.mr-2 {
    margin-right: 16px!important;
}

.mr-3 {
    margin-right: 24px!important;
}

.fw-400 {
    font-weight: 400!important;
}

.fw-600 {
    font-weight: 600!important;
}

.react-pdf__Page {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.w-100 {
    width: 100%!important
}

.h-100 {
    height: 100%!important
}

.sideLeft {
    background: #fafafa;
    border-right: 1px solid #ddd;
}

.gap-2 {
    height: 16px
}

.dd-menu {
    margin-top: 52px;
}

.action-menu {
    z-index: 99999;
    text-align: left;
    margin-top: 13.5px;
    max-width: 650px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    left: -30px !important;
}

.textError {
    color: #D61F26
}

.textWarning {
    color: #F57C00
}

.textSuccess {
    color: #5da14d
}

.textSecondary {
    color: #0000008a!important;
}

.textDefault {
    color: #000!important;
    text-decoration: none!important;
}

.text-center {
    text-align: center!important;
}

.text-left {
    text-align: left!important;
}

.text-right {
    text-align: right!important;
}

.disabledText {
    color: #000!important;
}

.textLight {
    color: #fff!important;
}

.list-item {
    padding: 16px!important;
    border-bottom: 1px solid #eee!important;
    min-width: 550px!important;
    width: 100%;
    padding-right: 8px!important;
}

.list-item-borderless {
    padding: 16px!important;
    min-width: 550px!important;
    padding-right: 8px!important;
}

.list-disabled {
    background: #fff!important;
}

.menu-text {
    min-width: 500px!important;
    padding: 18px!important;
    overflow-y: hidden!important;
}

.sm-list-item {
    padding: 16px!important;
    border-bottom: 1px solid #eee!important;
    min-width: 300px!important;
}

.sm-list-item-borderless {
    padding: 16px!important;
    min-width: 300px!important;
}

.sm-list-item-min {
    padding: 12px 0 12px 0!important;
}

.sm-menu-text {
    min-width: 300px!important;
    padding: 18px!important;
    overflow-y: hidden!important;
}

.dd-menu .MuiPaper-root::-webkit-scrollbar {
    width:0.3em!important
}

.scrollbar::-webkit-scrollbar {
    width:0.3em
}

.scrollbar::-webkit-scrollbar-track {
    background: #fafafa;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #bbb;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
} 

.bgLight {
    background: #fff!important;
}

.iconCustom {
    vertical-align: 0
}

.iconCustomMargin {
    margin-right: 8px;
}

.iconStyle {
    margin-right:8px;
    color: rgba(0,0,0,0.65)
}

.icon-duotone-red {
    --fa-primary-color: '#C70C25';
    --fa-secondary-color: '#F5F2F2';
}
.icon-duotone-green {
    --fa-primary-color: '#F5F2F2';
    --fa-secondary-color: '#limegreen';
}

.csvLink a,
.csvLink a:active,
.csvLink a:visited {
    color: #000;
    text-decoration: none;
}

.thumbnailContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
}

.thumbnail {
    max-width: 35px;
    max-height: 35px;
}

.clenawareBlue {
    color: #0282C6;
}

.content-light-white {
    word-wrap: break-word;
    background: #fff!important;
    border: 1px solid #ddd;
    border-radius: 2px
}

.keypad-value {
    font-size: 30px!important;
    font-weight: 300!important;
    color: #000!important;
}

.icon-shake {
    animation: rumble 0.75s infinite;
    transform: translate3d(0, 0, 0);
    transform-origin: initial;
}

/* Animations */
@keyframes ripple {
    from {
        transform: scale(.8);
        opacity: 1;
    }
    to {
        transform: scale(2.4);
        opacity: 0;
    }
}

@keyframes rumble {
    50%, 75% {
        transform: translate3d(-2px, 0, 0);
    }
    62.5%, 87.5% {
        transform: translate3d(2px, 0, 0);
    }
    0%, 37.5%, 100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes shake {
    10%, 15% {
        transform: translate3d(-3px, 0, 0);
    }
    12.5%, 17.5% {
        transform: translate3d(3px, 0, 0);
    }
    0%, 7.5%, 20%, 100% {
        transform: translate3d(0, 0, 0);
    }
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width:  calc(100% - 4em) !important;
    height: calc(100% - 4em) !important;
    block-size: fit-content;
}

.link {
    color: #0282c6;
    text-decoration: none;
}

.hover:hover {
    cursor: pointer;
}

.task-completed { 
    color: #4CAF50;
}

.task-in-progress {
    color: #F57F17;
}

.task-new { 
    color: #0288D1;
}