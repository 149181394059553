.emailPreveiew * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}

.emailPreveiew > * > * > * > * > * > * > * > * > * {
    width: 100% !important;
    height: 100% !important;
    min-height: 100px;
}