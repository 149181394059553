.noBorder .MuiOutlinedInput-notchedOutline  {
    border-width: 0px !important;
    border-color: #0282c6 !important;
}
  
.noBorder .Mui-focused .MuiOutlinedInput-notchedOutline  {
    border-color: #0282c6 !important;
}

.whiteBorder .MuiOutlinedInput-notchedOutline  {
    border-width: 0px !important;
    border-color: #fff !important;
}