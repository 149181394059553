.WYSIWYG-editor {
    padding: 0 0.5em;
}

.WYSIWYG-editor .public-DraftStyleDefault-block {
    margin: 0.5em 0;
}

.WYSIWYG-container {
    border: 1px solid #acacac;
    border-radius: 0.15em;
}

.rdw-editor-toolbar {
    background-color: #0282c636;
}